<template>
  <div>
    <section class="bg-light py-5">
      <div class="container text-center">
        <h1 class="heading">BS Blog - Projects</h1>
        <div class="text-dark">Various projects and works I've been involved in as part of my career.</div>
      </div>
    </section>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <project-list/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectList from "@/components/ProjectList";

export default {
  components: {
    ProjectList,
  },
  metaInfo() {
    let pageTitle = "My Projects | BS Blog";
    let pageDesc = 'Various projects from around the web. Drupal, Github, Websites, etc.';

    return {
      title: pageTitle,
      meta: [
        {name: 'description', content: pageDesc},
        {name: 'twitter:title', content: pageTitle},
        {name: 'twitter:description', content: pageDesc},
        {property: 'og:title', content: pageTitle}
      ]
    }
  },
}
</script>
