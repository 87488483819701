const contentful = require('contentful')
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { MARKS, BLOCKS, INLINES } from '@contentful/rich-text-types';

// use default environment config for convenience
// these will be set via 'env' property
const config = {
  space: process.env.VUE_APP_CTF_SPACE_ID,
  accessToken: process.env.VUE_APP_CTF_CDA_ACCESS_TOKEN
}

// Helpers.
export default {
  createClient: function () {
    return contentful.createClient(config)
  },
  richText: function(text) {
    const richTextOptions = {
      renderMark: {
        [MARKS.CODE]: text => `<span class="bg-white rounded-lg py-1 px-2"><em>${text}</em></span>`
      },
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          const contentType = node.data.target.sys.contentType.sys.id

          switch (contentType) {
            // Contentful Snippet entity.
            case 'snippet': {
              let { type, snippet } = node.data.target.fields
              // Escape HTML.
              snippet = snippet.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
              return `<pre><code class="${type}">${snippet}</code></pre>`
            }
          }
        },
        [BLOCKS.EMBEDDED_ASSET]: asset => {
          const { title, file } = asset.data.target.fields
          const mimeType = file.contentType
          const mimeGroup = mimeType.split('/')[0]
          console.log(file.url);
          switch (mimeGroup) {
            case 'image':
              return `<img class="img-fluid mb-3" alt="${title || ''}" src="${file.url}" />`
            case 'text':
              return `<a href="${file.url}">Download</a>`
          }
        },
        [INLINES.HYPERLINK]: (node, next) => {
          return `<a href="${node.data.uri}"${node.data.uri.startsWith('https://www.bryansharpe.com') ? '' : ' target="_blank"'}>${next(node.content)}</a>`;
        }
      }
    }
    return documentToHtmlString(text, richTextOptions)
  }
}
