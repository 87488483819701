<template>
  <div class="mt-3">
    <h2 class="mb-3 pb-2 border-bottom">Latest Posts:</h2>

    <section v-if="errored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>

    <section v-else>
      <div v-if="loading">Loading...</div>

      <div v-else v-for="item in info" :key="item.sys.id" class="blog-item row mb-3 position-relative bg-light py-3">
        <div class="media col-12 col-md-4">
          <img v-if="item.fields.image" :src="item.fields.image.fields.file.url + '?fit=thumb&w=300&h=200&f=top_left'" alt="" class="img-fluid w-100">
          <img v-else src="../assets/images/default_blog.jpg" alt="Default Image" class="img-fluid w-100">
        </div>
        <div class="meta col-12 col-md-8 position-static d-flex flex-column">
          <h2 class="mb-0 blog-title text-primary" >{{ item.fields.title }}</h2>
          <small class="text-dark"><em>{{ item.fields.publishedDate }}</em></small>
          <div class="body flex-grow-1 mb-2" v-html="getTeaser(item.fields.body)" ></div>
          <router-link class="stretched-link" :to="{name: 'post', params: { alias: item.fields.slug }}">Read more...</router-link>
        </div>
      </div>

      <div class="my-4">
        <ul class="pagination pagination-md justify-content-center text-center">
          <li v-if="page > 0" class="page-item">
            <button class="page-link" @click="prevPage">Previous</button>
          </li>
          <li class="page-link text-dark" style="background-color: inherit">
            Page {{ page + 1 }}
          </li>
          <li v-if="total > info.length + (page * limit)" class="page-item">
            <button class="page-link" @click="nextPage">Next</button>
          </li>
        </ul>
      </div>

    </section>
  </div>

</template>

<script>
  import contentful from '../plugins/contentful'
  const trimHtml = require('trim-html')

  export default {
    data() {
      return {
        info: '',
        loading: true,
        errored: false,
        page: 0,
        total: 0,
        limit: 5,
      }
    },
    methods: {
      getPosts() {
        contentful.createClient().getEntries({
          content_type: 'article',
          limit: this.limit,
          skip: this.page * this.limit,
          order: '-fields.publishedDate'
        }).then(response => {
          this.info = response.items
          this.total = response.total
        }).catch(error => {
          this.errored = error
        }).finally(() => this.loading = false)
      },
      getTeaser(body) {
        const element = {
          "data": {},
          "content": [body.content[0]],
          "nodeType": "paragraph"
        };
        let teaser = trimHtml(contentful.richText(element), { limit: 200 })
        return teaser.html;
      },
      nextPage() {
        this.page++;
        this.getPosts();
      },
      prevPage() {
        if (this.page > 0) {
          this.page--;
          this.getPosts();
        }
      }
    },
    mounted() {
      this.page = 0;
      this.getPosts();
    }
  }
</script>
