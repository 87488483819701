import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import router from './router'
import VueMeta from 'vue-meta'
import { ButtonPlugin, NavbarPlugin, ModalPlugin, CardPlugin } from 'bootstrap-vue'

// Assets
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import '../node_modules/highlight.js/styles/monokai-sublime.css'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-C47CRLE4K7" }
}, router);

Vue.use(ButtonPlugin).use(NavbarPlugin).use(ModalPlugin).use(CardPlugin)
Vue.use(VueMeta)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
