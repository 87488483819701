<template>
  <div class="container pt-4 about-section">
    <div class="row">
      <div class="col-12 col-lg-10 mx-auto">
        <h1>About Me</h1>

        <p>Hi, I'm Bryan. I live on Vancouver Island, in BC, Canada. I have a loving wife of over 10yrs, three amazing
          daughters, and love all things tech. In my spare time outside of family I enjoy playing beer league hockey,
          skim boarding,
          practicing guitar, and mountain biking in our beautiful back country.</p>

        <img class="img-fluid mx-auto mb-3" src="../assets/images/famjam.jpg" alt="The Family">

        <h3>My Skill and Experience</h3>

        <p>I've been dabbling in PHP/Web since about 1998 when I needed to make a website for a punk band
          that played in as a teenager. I of course created this using Macromedia Flash as was the style in the day
          :) I've obviously come a long way since then (though I do miss playing in flash), through various jobs I kept
          learning and getting better at HTML/CSS/JS/Dev ops/etc and became familiar with the PHP-based CMS Drupal.
          After a few years of some freelance, in 2012 I finally jumped into a career in Drupal at
          <a href="https://www.acromediainc.com" target="_blank">Acro Media Inc.</a> in Kelowna, BC.</p>

        <p>
          Eventually moving into a team lead and senior software developer role, I worked with large e-commerce clients
          such
          as
          <a href="https://www.telus.com" target="_blank">Telus</a>, <a href="https://www.eikondevice.com/"
                                                                        target="_blank">Eikon</a>, and
          <a href="https://www.koodomobile.com/" target="_blank">Koodo</a> to architect, manage, and develop custom
          solutions for online products, orders and
          fulfillment.
        </p>

        <p>In 2016 I moved into a Lead Developer role with <a href="https://imagexmedia.com/" target="_blank">ImageX
          Media</a>, in 2018 into the role of Software Architect, and in 2021 promoted to Director of Software Architecture. At ImageX I work with teams to architect, develop
          and
          support a wide variety of web
          projects
          for clients all over the globe, focusing mainly on the EDU sector and Drupal development.
        </p>

        <h3>About the Blog</h3>
        <p>It's why you're here right? This blog is a place for me to place my findings/learnings as I navigate my
          career. I will try to post often with tutorials or guidance, mostly involving PHP/Drupal, but am open to
          suggestions :)</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo() {
    let pageTitle = "About Me - Bryan Sharpe | BS Blog";
    let pageDesc = 'Hi, I\'m Bryan. I live on Vancouver Island, in BC, Canada. I have a loving wife of over 10yrs, three amazing daughters, and love all things tech. In my spare time outside of family I enjoy playing beer league hockey, skim boarding, practicing guitar, and mountain biking in our beautiful back country.'

    return {
      title: pageTitle,
      meta: [
        {name: 'description', content: pageDesc},
        {name: 'twitter:title', content: pageTitle},
        {name: 'twitter:description', content: pageDesc},
        {property: 'og:title', content: pageTitle}
      ]
    }
  },
}
</script>
