<template>
  <div>
    <section class="bg-light py-5">
      <div class="container text-center">
        <h1 class="heading">BS Blog - {{ tag }}</h1>
        <div class="text-dark">All posts relating to {{ tag }}.</div>
      </div>
    </section>
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <blog-tags :tag="tag" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BlogTags from "@/components/BlogTags";

  export default {
    name: 'Tags',
    components: {
      BlogTags,
    },
    data() {
      return {
        tag: null,
      }
    },
    created() {
      this.tag = this.$route.params.tag
    },
    metaInfo() {
      let pageTitle = "Posts about " + this.tag + " | BS Blog";
      let pageDesc = 'Development blog articles from Drupal to Javascript to Docker.'

      return {
        title: pageTitle,
        meta: [
          {name: 'description', content: pageDesc},
          {name: 'twitter:title', content: pageTitle},
          {name: 'twitter:description', content: pageDesc},
          {property: 'og:title', content: pageTitle}
        ]
      }
    },
  }
</script>
