import Vue from 'vue'
import VueRouter from 'vue-router';

// Components.
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import BlogPost from "../components/BlogPost";
import Tags from "../views/Tags";
import Projects from "../views/Projects";
import NotFound from "../components/NotFound";

Vue.use(VueRouter)

const routes = [
  {name: 'home', path: "/", component: Home},
  {name: 'about', path: "/about", component: About},
  {name: 'projects', path: "/projects", component: Projects, meta: {title: 'Projects | BS Blog'}},
  {name: 'post', path: "/post/:alias+", props: true, component: BlogPost, meta: {title: 'Blog Post | BS Blog'}},
  {name: 'tag', path: "/tag/:tag", component: Tags, meta: {title: 'Blog items by tag | BS Blog'}},
  {path: '/404', component: NotFound},
  {path: '*', redirect: '/404'},
];

export default new VueRouter({
  routes: routes,
  mode: 'history',
})

