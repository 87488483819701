<template>
  <div>
    <section class="bg-light py-5">
      <div class="container text-center">
        <h1 class="heading">BS - Dev Blog</h1>
        <div class="text-dark">Welcome to the BS (my initials) Blog.</div>
      </div>
    </section>
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <blog-list />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BlogList from "../components/BlogList";

  export default {
    components: {
      BlogList,
    },
  }
</script>
