<template>
  <div class="mt-3">
    <div class="search-bar border-bottom mb-3 pb-4">
      <h2>Search:</h2>
      <div class="form-search mb-3 pb-2">
        <label for="keyword" class="sr-only"></label>
        <input id="keyword" class="form-control form-control-lg mb-2" type="text" name="keyword" v-model="keyword">
        <div class="filters d-flex" v-if="typesFilter.length">
          <div v-for="(name, index) in typesFilter" :key="index" class="custom-control custom-checkbox mr-3">
            <input type="checkbox" class="custom-control-input"
              :id="'filter-' + name"
              :value="name"
              v-model="typesChecked"
            >
            <label :for="'filter-' + name" class="custom-control-label">{{ name }}</label>
          </div>
        </div>
      </div>
    </div>

    <section v-if="errored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>

    <section v-else>
      <div v-if="loading">Loading...</div>

      <div v-else-if="!resultQuery.length">
        <h4>Sorry, no items match your query...</h4>
      </div>

      <transition-group v-else class="card-columns" name="fade" tag="div">
        <b-card v-for="item in resultQuery" v-bind:key="item.sys.id"
          :border-variant="getBorderType(item.fields.type.fields.title.toLowerCase())"
          :title="item.fields.title"
          :img-src="item.fields.screenshot ? item.fields.screenshot.fields.file.url : item.fields.type.fields.logo.fields.file.url + '?fit=pad&w=300&h=150'"
          :img-alt="item.fields.type.fields.logo.fields.file.title"
          img-top
          tag="article"
          class="mb-3 shadow-sm"
          footer-class="d-flex justify-content-end"
        >
          <b-card-text>
            {{ item.fields.teaser }}
          </b-card-text>

          <template #footer>
            <b-button target="_blank" :href="item.fields.url" :variant="getBorderType(item.fields.type.fields.title.toLowerCase())">View Project</b-button>
          </template>

        </b-card>
      </transition-group>

    </section>
  </div>

</template>

<script>
  import contentful from '../plugins/contentful'

  export default {
    data() {
      return {
        info: '',
        loading: true,
        errored: false,
        filterInfo: '',
        typesFilter: [],
        keyword: '',
        typesChecked: [],
      }
    },
    computed: {
      resultQuery() {
        let filtered = this.info
        if (this.keyword.length) {
          filtered = filtered.filter((item)=>{
            return item.fields.title.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1
          })
        }

        if (this.typesChecked.length) {
          filtered = filtered.filter((item)=>{
            return this.typesChecked.indexOf(item.fields.type.fields.title) !== -1
          })
        }

        return filtered
      }
    },
    methods: {
      getBorderType(type) {
        switch (type) {
          case 'drupal':
            return 'info';
          case 'website':
            return 'success'
          default:
            return 'dark'
        }
      },
      getPosts() {
        contentful.createClient().getEntries({
          content_type: 'project',
        }).then(response => {
          response.items.forEach((value) => {
            let filterName = value.fields.type.fields.title
            if (this.typesFilter.indexOf(filterName) === -1) {
              this.typesFilter.push(filterName)
            }
          });
          this.info = response.items.sort(function () {return Math.random() - 0.5;});
        }).catch(error => {
          this.errored = error
        }).finally(() => this.loading = false)
      },
    },
    created() {
      this.getPosts();
    },
  }
</script>

<style lang="scss">
.fade-enter {
	transform: scale(0.5) translatey(-80px);
	opacity:0;
}

.fade-leave-to {
	transform: translatey(30px);
	opacity:0;
}

.fade-leave-active {
  left: 0;
	position: absolute;
  max-width: 250px;
	z-index:-1;
}
.card {
  transition: all .35s ease-in-out;
}
</style>
