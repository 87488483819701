<template>
  <article class="blog-post py-5 bg-light">
    <div class="container">

      <section>
        <div v-if="loading">Loading...</div>

        <div v-else>

          <header class="blog-post-header">
            <h1 class="title mb-2">{{ info.fields.title }}</h1>
            <div class="meta"><span class="date">Published {{ dateFormat(info.fields.publishedDate) }}</span> |
              <span class="comment"><a href="#comments"><DisqusCount shortname="bryansharpe" :identifier="'/post/' + alias" /></a></span>
            </div>

            <div class="blog-tags py-3 border-bottom mb-3">
              <span>Tags: </span>
              <router-link
                :to="{name: 'tag', params: { tag: tag.fields.name }}" v-for="tag in info.fields.tags"
                :key="tag.sys.id" class="badge badge-success mr-1 p-1 px-2 text-white">
                {{ tag.fields.name }}
              </router-link>
            </div>

          </header>

          <div class="content">

            <figure v-if="info.fields.showImage" class="blog-banner">
              <!-- <img :src="info.fields.image" :alt="info.field_image[0].alt" class="img-fluid">-->
            </figure>

            <div class="blog-post-body" v-html="renderContent(info.fields.body)"></div>

            <div id="comments" class="comments mt-5">
              <Disqus shortname='bryansharpe' />
            </div>
          </div>
        </div>
      </section>

    </div>
  </article>
</template>

<script>
  import * as dayjs from 'dayjs'
  import { Disqus, DisqusCount } from 'vue-disqus'
  import hljs from 'highlight.js/lib/core';

  // Only import the langs we need to keep size down.
  ['javascript', 'php', 'bash', 'yaml'].forEach((langName) => {
    const langModule = require(`highlight.js/lib/languages/${langName}`);
    hljs.registerLanguage(langName, langModule);
  });

  import contentful from '../plugins/contentful'
  const trimHtml = require('trim-html')
  /* eslint-disable no-unused-vars */
  const hljsb = require('highlightjs-badge/highlightjs-badge.min')
  /* eslint-enable no-unused-vars */

  export default {
    name: 'blog-post',
    components: {
      Disqus,
      DisqusCount
    },
    metaInfo() {
      let pageTitle = this.info ? this.info.fields.title : "Blog Post | BS Blog";
      let pageDesc = this.info ? this.getDescription(this.info.fields.body) : 'Blog post';

      return {
        title: pageTitle,
        meta: [
          {name: 'description', content: pageDesc},
          {name: 'twitter:title', content: pageTitle},
          {name: 'twitter:description', content: pageDesc},
          {property: 'og:title', content: pageTitle}
        ]
      }
    },
    data() {
      return {
        info: null,
        loading: true,
        errored: false,
        base_url: process.env.VUE_APP_JSONAPI_BASE,
        alias: null,
      }
    },
    methods: {
      dateFormat(value) {
        if (value) {
          return dayjs(String(value)).format('MMM DD, YYYY')
        }
      },
      renderContent(content) {
        return contentful.richText(content)
      },
      getDescription(body) {
        const element = {
          "data": {},
          "content": [body.content[0]],
          "nodeType": "paragraph"
        };
        let teaser = trimHtml(contentful.richText(element), {limit: 150})
        let tmp = document.createElement("DIV");
        tmp.innerHTML = teaser.html;
        return tmp.textContent || tmp.innerText || "";
      },
      getPost() {
        contentful.createClient().getEntries({
          content_type: 'article',
          'fields.slug[match]': this.alias,
        }).then(response => {
          this.info = response.items[0]
        }).catch(error => {
          this.errored = error
          return this.$router.push('/')
        }).finally(() => {
          this.loading = false
        })
      },
      highlightJS() {
        // on first bind, highlight all targets
        var targets = this.$el.querySelectorAll('pre code');
        var target;
        var i;

        for (i = 0; i < targets.length; i += 1) {
          target = targets[i];
          hljs.highlightBlock(target);
        }

        // add HighlightJS-badge (options are optional)
        var options = {   // optional
          contentSelector: ".blog-post",
          // CSS class(es) used to render the copy icon.
          copyIconClass: "fas fa-copy",
          // CSS class(es) used to render the done icon.
          checkIconClass: "fas fa-check text-success"
        };
        window.highlightJsBadge(options);

      }
    },
    created() {
      this.alias = this.$route.params.alias;
      this.getPost()
    },
    updated() {
      this.highlightJS()
    }
  }
</script>
