<template>
  <div id="site-wrapper">
    <Header/>
    <div class="main-wrapper">
      <div id="content" class="mb-5">
        <router-view/>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from './components/structure/Header'
import Footer from './components/structure/Footer'

export default {
  name: 'BSBlog',
  data() {
    return {
      logo: 'https://www.bryansharpe.com' + require('./assets/images/social_blog.jpg')
    }
  },
  metaInfo() {
    return {
      title: "Drupal / Developer Blog | BS Blog",
      meta: [
        {
          name: 'description',
          content: 'Developer blog, mostly around Drupal, PHP, Docker, and Symfony. Welcome to my blog. Drupal 8, Drupal 9.'
        },
        // Twitter Card
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Drupal / Developer Blog | BS Blog'},
        {name: 'twitter:description', content: 'Developer blog, mostly around Drupal, PHP, Docker, and Symfony. Welcome to my blog. Drupal 8, Drupal 9.'},
        {name: 'twitter:image', content: this.logo},
        // Facebook OpenGraph
        {property: 'og:title', content: "Drupal / Developer Blog | BS Blog"},
        {property: 'og:site_name', content: 'BS Blog'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  this.logo},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  },
  components: {
    Header,
    Footer,
  }
}
</script>

<style lang="scss">
  @import './sass/app';
</style>
